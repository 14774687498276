var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "label",
              { staticClass: "form-control-label", attrs: { for: _vm.id } },
              [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.label
                        ? _vm.label
                        : _vm.$t("documento-multi.titulo-default")
                    )
                  ),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [_c("alerta", { ref: "alerta", attrs: { id: "alertContacto" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        {
          staticClass: "flex h-screen items-center justify-center text-center",
        },
        [
          _c(
            "b-col",
            {
              class: [
                "p-12",
                "border-file",
                _vm.archivos.length == 0 || _vm.estadoValido
                  ? ""
                  : "border-file-invalid",
              ],
              on: {
                dragover: _vm.dragover,
                dragleave: _vm.dragleave,
                drop: _vm.drop,
              },
            },
            [
              _vm.archivos.length > 0
                ? _c(
                    "b-container",
                    {
                      staticClass: "text-sm p-1 table-responsive",
                      staticStyle: { "max-height": "19em" },
                      attrs: { fluid: "" },
                    },
                    _vm._l(_vm.archivos, function (currArchvio) {
                      return _c(
                        "b-row",
                        {
                          key: currArchvio.file.nombre,
                          staticStyle: {
                            "margin-left": "10px",
                            "margin-right": "10px",
                          },
                        },
                        [
                          _c(
                            "b-col",
                            { staticClass: "acciones", attrs: { cols: "1" } },
                            [
                              currArchvio.file.uri
                                ? _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: currArchvio.file.uri,
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticStyle: {
                                          "font-size": "2.5rem",
                                          color: "#1553b7",
                                        },
                                        attrs: {
                                          icon: currArchvio.file.nombre
                                            .toUpperCase()
                                            .includes("XLS")
                                            ? "file-excel"
                                            : "file-text",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _c("font-awesome-icon", {
                                    staticStyle: {
                                      "font-size": "2.5rem",
                                      color: "#1553b7",
                                    },
                                    attrs: {
                                      icon: currArchvio.file.nombre
                                        .toUpperCase()
                                        .includes("XLS")
                                        ? "file-excel"
                                        : "file-text",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { cols: "8" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { staticClass: "text-left" }, [
                                    currArchvio.file.uri
                                      ? _c("span", [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: currArchvio.file.uri,
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    currArchvio.file.nombre
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(currArchvio.file.nombre) +
                                              "\n                "
                                          ),
                                        ]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "text-left" },
                                    [
                                      _c("span", { staticClass: "bold-600" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              (
                                                currArchvio.file.size /
                                                1024 ** 2
                                              ).toFixed(3)
                                            ) +
                                            " MB "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-invalid-feedback",
                                        {
                                          staticStyle: {
                                            "margin-left": "1rem",
                                          },
                                          attrs: { state: _vm.estado },
                                        },
                                        [
                                          !currArchvio.formatValid
                                            ? _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "documento-multi.mensajes-invalidos.tipo-archivo"
                                                    )
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !currArchvio.sizeValid
                                            ? _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "documento-multi.mensajes-invalidos.tamanio-archivo",
                                                      {
                                                        size: (
                                                          currArchvio.file
                                                            .size /
                                                          1024 ** 2
                                                        ).toFixed(3),
                                                      }
                                                    )
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          !currArchvio.nameValid
                                            ? _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    _vm.$t(
                                                      "documento-multi.mensajes-invalidos.nombre-archivo"
                                                    )
                                                  ),
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { staticClass: "text-right", attrs: { cols: "3" } },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "ml-2 btn btn-outline-primary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(currArchvio)
                                    },
                                  },
                                },
                                [
                                  _c("span", {
                                    staticClass: "icon-eliminar acciones",
                                  }),
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "documento-multi.acciones.eliminar"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.archivos.length > 0 ? _c("hr") : _vm._e(),
              _vm._v(" "),
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("input", {
                          ref: "inputFile",
                          staticClass:
                            "w-px h-px opacity-0 overflow-hidden absolute",
                          attrs: {
                            type: "file",
                            id: "file_" + _vm.id,
                            accept: _vm.tiposMime,
                            disabled: _vm.disabled,
                            multiple: "true",
                          },
                          on: { change: _vm.onChange },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "block cursor-pointer",
                            attrs: { for: "file_" + _vm.id },
                          },
                          [
                            _c("div", [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t(
                                      "documento-multi.textos.arrastrar-y-soltar"
                                    )
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("p", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("documento-multi.textos.o")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", [
                                _c(
                                  "span",
                                  { staticClass: "btn btn-outline-primary" },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(
                                            "documento-multi.textos.seleccionar"
                                          )
                                        ) +
                                        "\n                    "
                                    ),
                                    _c("span", {
                                      staticClass: "icon-cargar acciones",
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("small", { staticClass: "form-text text-muted" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.description
                      ? _vm.description
                      : _vm.$t("documento-multi.descripcion-default", {
                          size:
                            (_vm.tamanioMaximo / 1024) % 1 == 0
                              ? (_vm.tamanioMaximo / 1024).toFixed()
                              : (_vm.tamanioMaximo / 1024).toFixed(2),
                          tipos: _vm.tiposMime,
                        })
                  ) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }