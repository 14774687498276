import Component, { mixins } from 'vue-class-component';
import { Emit, Prop, PropSync, Ref, VModel, Watch } from 'vue-property-decorator';
import JhiDataUtils from '@/shared/data/data-utils.service';
import type AlertaComponent from '@/components/alerta/alerta.component';
import { FileValid } from '../commons/file.valid.model';
import { InformacionArchivo } from '@/shared/nomina-client';

@Component({
  inheritAttrs: false,
})
export default class InputFileMultipleComponent extends mixins(JhiDataUtils) {
  archivos: Array<FileValid> = [];
  estadoValido = false;

  @Prop({ required: true })
  id: string;

  @Prop({ default: '' })
  button: string;

  @Prop({ default: '' })
  label: string;

  @Prop({ default: '' })
  tiposMime: string;

  @Prop({ default: true })
  valid: boolean;

  @VModel({ required: true })
  documentos: Array<InformacionArchivo>;

  @Prop({ default: '' })
  description: string;

  @Prop({ required: true })
  disabled: boolean;

  @PropSync('estadoInvalido', { type: Boolean })
  estado;

  /**
   * Tamaño máximo en KB
   */
  @Prop({ required: true, type: Number })
  tamanioMaximo: number;

  @Ref() readonly inputFile!: HTMLInputElement;

  @Ref()
  alerta!: AlertaComponent;

  mounted() {
    for (let idx = 0; idx < this.documentos.length; idx++) {
      this.archivos.push(this.validarArchivo(this.documentos[idx]));
    }
    this.estado = this.validarEstado(this.archivos);
    this.estadoValido = this.validarEstado(this.archivos);
  }

  @Watch('documentos')
  checkReset(): void {
    if (this.documentos.length == 0) {
      this.reiniciarComponente();
    }
  }

  @Emit()
  onChange(): Promise<boolean> {
    return new Promise(resolve => {
      let mensajeErrores = '';
      let mensajeFinal = '';
      let cargoArchivos = false;

      for (let idx = 0; idx < this.inputFile.files.length; idx++) {
        const file = this.inputFile.files[idx];
        let esRepetido = false;

        for (let idxVal = 0; idxVal < this.documentos.length; idxVal++) {
          if (this.documentos[idxVal].nombre == file.name) {
            esRepetido = true;
          }
        }

        if (esRepetido) {
          mensajeErrores += '<li>' + this.$t('documento-multi.mensajes-invalidos.nombre-duplicado', { nombre: file.name }) + '</li>';
        } else {
          if (!this.validarTipoArchivo(file.name)) {
            mensajeErrores += '<li>' + this.$t('documento-multi.mensajes-invalidos.tipo-archivo', { nombre: file.name }) + '</li>';
          } else if (!this.validarNombreArchivo(file.name)) {
            mensajeErrores += '<li>' + this.$t('documento-multi.mensajes-invalidos.nombre-archivo', { nombre: file.name }) + '</li>';
          } else if (!this.validarTamanioArchivo(file.size)) {
            mensajeErrores += '<li>' + this.$t('documento-multi.mensajes-invalidos.tamanio-archivo', { nombre: file.name }) + '</li>';
          } else {
            this.toBase64(file, base64Data => {
              const arch: InformacionArchivo = {
                mediaType: file.type,
                contenido: base64Data,
                nombre: file.name,
                size: file.size,
                uri: URL.createObjectURL(file),
              };

              this.documentos.push(arch);
              this.archivos.push(this.validarArchivo(arch));

              this.estado = this.validarEstado(this.archivos);
              this.estadoValido = this.validarEstado(this.archivos);
            });

            cargoArchivos = true;
          }
        }
      }

      this.inputFile.value = '';

      if (cargoArchivos) {
        mensajeFinal =
          '<div>' +
          this.$t(mensajeErrores.length > 0 ? 'documento-multi.acciones.cargado-parcial' : 'documento-multi.acciones.cargado-todo', {
            boton: this.button ? this.button : this.$t('documento-multi.boton-guardar-defecto'),
          }) +
          '</div>';
      }

      if (mensajeErrores.length > 0) {
        mensajeFinal +=
          (cargoArchivos ? '' : this.$t('documento-multi.acciones.cargado-ninguno')) +
          '<hr style="margin-top: 0.5rem; margin-bottom: 0.5rem;" /><ul style="margin-bottom: 0em !important;">' +
          mensajeErrores +
          '</ul>';
      }

      mensajeFinal = '<div>' + mensajeFinal + '</div>';

      if (cargoArchivos) {
        if (mensajeErrores.length > 0) {
          this.alerta.mostrar(mensajeFinal, 'warning', 60);
        } else {
          this.alerta.mostrar(mensajeFinal, 'success', 5);
        }
      } else {
        if (mensajeErrores.length > 0) {
          this.alerta.mostrar(mensajeFinal, 'danger', 60);
        } else {
          this.alerta.mostrar(mensajeFinal, 'info', 5);
        }
      }

      resolve(true);
    });
  }

  remove(currArchvio: FileValid): void {
    for (let idxVal = 0; idxVal < this.documentos.length; idxVal++) {
      const currDoc = this.documentos[idxVal];

      if (currDoc.nombre.toUpperCase() == currArchvio.file.nombre.toUpperCase()) {
        this.documentos.splice(idxVal, 1);
        break;
      }
    }

    for (let idxVal = 0; idxVal < this.archivos.length; idxVal++) {
      const currA = this.archivos[idxVal];

      if (currA.file.nombre.toUpperCase() == currArchvio.file.nombre.toUpperCase()) {
        this.archivos.splice(idxVal, 1);
        break;
      }
    }

    this.estado = this.validarEstado(this.archivos);
    this.estadoValido = this.validarEstado(this.archivos);

    this.alerta.mostrar(this.$t('documento-multi.acciones.eliminado'), 'primary', 5);
  }

  dragover(event): void {
    event.preventDefault();
  }

  dragleave(event): void {
    // Clean up
    event.currentTarget.classList.add('bg-gray-100');
    event.currentTarget.classList.remove('bg-green-300');
  }

  drop(event): void {
    event.preventDefault();
    this.inputFile.files = event.dataTransfer.files;
    this.onChange(); // Trigger the onChange event manually
    // Clean up
    event.currentTarget.classList.add('bg-gray-100');
    event.currentTarget.classList.remove('bg-green-300');
  }

  private validarArchivo(file: InformacionArchivo): FileValid {
    return {
      file: file,
      formatValid: this.validarTipoArchivo(file.nombre),
      nameValid: this.validarNombreArchivo(file.nombre),
      sizeValid: this.validarTamanioArchivo(file.size),
    };
  }

  private validarNombreArchivo(nombre: string): boolean {
    const regexp =
      /^([a-z0-9]|á|á|é|é|í|í|ó|ó|ú|ú|Á|Á|É|É|Í|Í|Ó|Ó|Ú|Ú|ñ|ñ|Ñ|Ñ|ä|ä|ë|ë|ï|ï|ö|ö|ü|ü|Ä|Ä|Ë|Ë|Ï|Ï|Ö|Ö|Ü|Ü|à|à|è|è|ì|ì|ò|ò|ù|ù|À|À|È|È|Ì|Ì|Ò|Ò|Ù|Ù|!|@|#|\$|%|\^|&|\*|\)|\(|\+|=|\[|\]|\}|\||\{|~|<|>|\.|_|-| )+\.[a-zA-Z]{3,4}$/im;
    return regexp.test(nombre) && nombre.length < 51;
  }

  private validarTamanioArchivo(size: number): boolean {
    return size / 1024 <= this.tamanioMaximo;
  }

  private validarTipoArchivo(nombre: string): boolean {
    const extensiones = this.tiposMime.split(',');
    for (const index in extensiones) {
      const extension = extensiones[index];
      if (nombre.toUpperCase().endsWith(extension.toUpperCase().trim())) {
        return true;
      }
    }
    return false;
  }

  private validarEstado(archivosValidos: Array<FileValid>): boolean {
    let esValido = true;

    for (const idx in archivosValidos) {
      const archivoValido = archivosValidos[idx];
      if (esValido && (!archivoValido.formatValid || !archivoValido.nameValid || !archivoValido.sizeValid)) {
        esValido = false;
      }
    }

    return esValido;
  }

  private reiniciarComponente(): void {
    this.archivos = [];
    this.estadoValido = false;
  }
}
