var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + " "),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _vm.tooltip
          ? _c(
              "span",
              [
                _c("b-icon", {
                  attrs: { id: _vm.id + "tooltip", icon: "question-circle" },
                }),
                _vm._v(" "),
                _c("b-tooltip", {
                  attrs: {
                    target: _vm.id + "tooltip",
                    title: _vm.tooltip,
                    placement: "right",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("b-form-input", {
            attrs: {
              id: _vm.id,
              state: _vm.valid,
              disabled: _vm.readonly,
              placeholder: _vm.placeholder,
              list: "my-list-id",
              autocomplete: "off",
            },
            on: {
              change: function ($event) {
                return _vm.cambio($event)
              },
              keyup: _vm.keyup,
            },
            model: {
              value: _vm.selectedId,
              callback: function ($$v) {
                _vm.selectedId = $$v
              },
              expression: "selectedId",
            },
          }),
          _vm._v(" "),
          _c(
            "datalist",
            { attrs: { id: "my-list-id" } },
            _vm._l(_vm.options, function (option, id) {
              return _c("option", { key: id }, [_vm._v(_vm._s(option.text))])
            }),
            0
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form-invalid-feedback",
        [
          _c("validations-commons", {
            attrs: {
              requiredValue: _vm.validationsCommons.requiredValue,
              requiredMessage: _vm.validationsCommons.requiredMessage,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.description
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }