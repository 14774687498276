var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "label",
          { staticClass: "form-control-label", attrs: { for: _vm.id } },
          [_vm._v(_vm._s(_vm.label))]
        ),
        _vm._v(" "),
        _c("alerta", { ref: "alerta", attrs: { id: "alertContacto" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "flex w-full h-screen items-center justify-center text-center",
          },
          [
            _c(
              "div",
              {
                staticClass: "p-12 border-file",
                on: {
                  dragover: _vm.dragover,
                  dragleave: _vm.dragleave,
                  drop: _vm.drop,
                },
              },
              [
                this.filelist.length === 0
                  ? _c("div", [
                      _c("input", {
                        ref: "inputFile",
                        staticClass:
                          "w-px h-px opacity-0 overflow-hidden absolute",
                        attrs: {
                          type: "file",
                          id: "file_" + _vm.id,
                          accept: _vm.tiposMime,
                          disabled: _vm.disabled,
                        },
                        on: { change: _vm.onChange },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "block cursor-pointer",
                          attrs: { for: "file_" + _vm.id },
                        },
                        [
                          _c("div", [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("documento.drag")),
                              },
                            }),
                            _vm._v(" "),
                            _c("p", {
                              domProps: {
                                textContent: _vm._s(_vm.$t("documento.or")),
                              },
                            }),
                            _vm._v(" "),
                            _c("div", [
                              _c(
                                "span",
                                { staticClass: "btn btn-outline-primary" },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.$t("documento.select")) +
                                      "\n                  "
                                  ),
                                  _c("span", {
                                    staticClass: "icon-cargar acciones",
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                    ])
                  : _c(
                      "div",
                      { staticClass: "text-sm p-1" },
                      [
                        _c(
                          "b-container",
                          {
                            staticClass:
                              "bv-example-row bv-example-row-flex-cols",
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "left-felx",
                                    attrs: { col: "", lg: "6" },
                                  },
                                  [
                                    _vm.documento && _vm.documento.uri
                                      ? _c("span", [
                                          _vm.documento.uri
                                            ? _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: _vm.documento.uri,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    { staticClass: "acciones" },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        staticStyle: {
                                                          "font-size": "2rem",
                                                          color: "#1553b7",
                                                        },
                                                        attrs: {
                                                          icon: "file-excel",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _c(
                                          "span",
                                          { staticClass: "acciones" },
                                          [
                                            _c("font-awesome-icon", {
                                              staticStyle: {
                                                "font-size": "2rem",
                                                color: "#1553b7",
                                              },
                                              attrs: { icon: "file-excel" },
                                            }),
                                          ],
                                          1
                                        ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm.documento && _vm.documento.uri
                                        ? _c("span", [
                                            _vm.documento.uri
                                              ? _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: _vm.documento.uri,
                                                      target: "_blank",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.filelist[0].name
                                                        ) +
                                                        "\n                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.filelist[0].name)
                                            ),
                                          ]),
                                      _vm._v(" "),
                                      _c("br"),
                                      _vm._v(" "),
                                      _vm.filelist[0].size
                                        ? _c(
                                            "span",
                                            { staticClass: "bold-600" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  (
                                                    _vm.filelist[0].size /
                                                    1024 ** 2
                                                  ).toFixed(3)
                                                ) + " MB"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("input", {
                                      ref: "inputFile",
                                      staticClass:
                                        "w-px h-px opacity-0 overflow-hidden absolute",
                                      staticStyle: { display: "none" },
                                      attrs: {
                                        type: "file",
                                        id: "file_" + _vm.id,
                                        accept: _vm.tiposMime,
                                      },
                                      on: { change: _vm.onChange },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "right-felx",
                                    attrs: { col: "", lg: "6" },
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-outline-primary",
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.cambiarArchivo.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("documento.change")
                                            ),
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass:
                                            "icon-actualizar acciones",
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "ml-2 btn btn-outline-primary",
                                        attrs: {
                                          type: "button",
                                          title: "Eliminar",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.remove(true)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("documento.delete")
                                            ),
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("span", {
                                          staticClass: "icon-eliminar acciones",
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.description
          ? _c("small", { staticClass: "form-text text-muted" }, [
              _vm._v(_vm._s(_vm.description)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("b-form-invalid-feedback", { attrs: { state: !_vm.estado } }, [
          _vm.tipoFormatoInvalido
            ? _c("span", { attrs: { v: "" } }, [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("documento-excel.alert.type")),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.tamanioInvalido
            ? _c("span", { attrs: { v: "" } }, [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("documento-excel.alert.size")),
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.nombreInvalido
            ? _c("span", { attrs: { v: "" } }, [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("documento-excel.alert.name")),
                  },
                }),
              ])
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }