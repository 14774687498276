import SaveButtonComponent from '@/components/save-button/save-button.vue';
import DualButtonComponent from '@/components/dual-button/dual-button.vue';
import ActionButtonsComponent from '@/components/action-buttons/action-buttons.vue';
import DeleteModalComponent from '@/components/delete-modal/delete-modal.vue';

import InputTextComponent from '@/components/input-text/input-text.vue';
import InputTextAreaComponent from '@/components/input-text-area/input-text-area.vue';
import InputSelectOneComponent from '@/components/input-select-one/input-select-one.vue';
import InputSelectAutocompleteComponent from '@/components/input-select-autocomplete/input-select-autocomplete.vue';
import InputSelectRadioComponent from '@/components/input-select-radio/input-select-radio.vue';
import InputSelectManyComponent from '@/components/input-select-many/input-select-many.vue';
import TablePageableComponent from '@/components/table-pageable/table-pageable.vue';
import InputBooleanComponent from '@/components/input-boolean/input-boolean.vue';
import InputDateComponent from '@/components/input-date/input-date.vue';
import InputSelectYearComponent from '@/components/input-select-year/input-select-year.vue';
import CardsListComponent from '@/components/cards-list/cards-list.vue';
import ValidationsCommonsComponent from '@/components/validations-commons/validations-commons.vue';
import ContadorCaracteresComponent from '@/components/contador-caracteres/contador-caracteres.vue';
import AlertaComponent from '@/components/alerta/alerta.vue';
import AlerCardComponent from '@/components/alert-card/alert-card.vue';
import vMultiselectListbox from 'vue-multiselect-listbox';
import MultiselectTagsComponent from '@/components/multiselect-tags/multiselect-tags.vue';
import SkeletonComponent from '@/components/skeleton/skeleton.vue';
import InputPdfComponent from '@/components/input-pdf/input-pdf.vue';
import IndicadorCargaComponent from '@/components/indicador-carga/indicador-carga.vue';
import InputExcelComponent from '@/components/input-excel/input-excel.vue';
import InputFileMultipleComponent from '@/components/input-file-multiple/input-file-multiple.vue';
import DetalleNominaComponent from '@/components/detalle-nomina/detalle-nomina.vue';
import TimelineComponent from '@/components/timeline/timeline.vue';
import InputTxtComponent from '@/components/input-txt/input-txt.vue';

export function initBaseComponents(vue) {
  vue.component('c-delete-modal', DeleteModalComponent);
  vue.component('c-save-button', SaveButtonComponent);
  vue.component('c-dual-button', DualButtonComponent);
  vue.component('c-action-buttons', ActionButtonsComponent);
  vue.component('input-text-area', InputTextAreaComponent);
  vue.component('input-select-one', InputSelectOneComponent);
  vue.component('input-select-autocomplete', InputSelectAutocompleteComponent);
  vue.component('input-select-radio', InputSelectRadioComponent);
  vue.component('input-select-many', InputSelectManyComponent);
  vue.component('table-pageable', TablePageableComponent);
  vue.component('input-text', InputTextComponent);
  vue.component('input-date', InputDateComponent);
  vue.component('input-select-year', InputSelectYearComponent);
  vue.component('input-boolean', InputBooleanComponent);
  vue.component('cards-list', CardsListComponent);
  vue.component('validations-commons', ValidationsCommonsComponent);
  vue.component('contador-caracteres', ContadorCaracteresComponent);
  vue.component('alerta', AlertaComponent);
  vue.component('alerta-card', AlerCardComponent);
  vue.component('v-multiselect-listbox', vMultiselectListbox);
  vue.component('multiselect-tags', MultiselectTagsComponent);
  vue.component('skeleton', SkeletonComponent);
  vue.component('input-pdf', InputPdfComponent);
  vue.component('indicador-carga', IndicadorCargaComponent);
  vue.component('input-excel', InputExcelComponent);
  vue.component('input-file-multiple', InputFileMultipleComponent);
  vue.component('detalle-nomina', DetalleNominaComponent);
  vue.component('timeline', TimelineComponent);
  vue.component('input-txt', InputTxtComponent);
}
