
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync } from 'vue-property-decorator';

@Component
export default class ContadorCaracteresComponent extends Vue {
  @PropSync('value', { required: false, type: String })
  readonly texto!: string;

  @Prop({ required: true, type: Number })
  readonly max!: number;

  get caracteresRestantes() {
    if (!this.texto) {
      return this.max;
    }
    return this.max - this.texto.length;
  }
}
