import { render, staticRenderFns } from "./input-pdf.vue?vue&type=template&id=58d4e6da&scoped=true&"
import script from "./input-pdf.component.ts?vue&type=script&lang=ts&"
export * from "./input-pdf.component.ts?vue&type=script&lang=ts&"
import style0 from "./input-pdf.vue?vue&type=style&index=0&id=58d4e6da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d4e6da",
  null
  
)

export default component.exports