var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + " "),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _vm.tooltip || _vm.popover
          ? _c(
              "span",
              [
                _c("span", {
                  staticClass: "icon-info",
                  attrs: { id: _vm.id + "tooltip" },
                }),
                _vm._v(" "),
                _vm.tooltip
                  ? _c("b-tooltip", {
                      attrs: {
                        target: _vm.id + "tooltip",
                        title: _vm.tooltip,
                        placement: "right",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.popover
                  ? _c(
                      "b-popover",
                      {
                        attrs: {
                          target: _vm.id + "tooltip",
                          triggers: "hover",
                        },
                      },
                      [
                        _c("div", { staticClass: "p-3" }, [
                          _vm._v(
                            "\n          " + _vm._s(_vm.popover) + "\n        "
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("b-form-select", {
        attrs: {
          id: _vm.id,
          options: _vm.years,
          state: _vm.valid,
          disabled: _vm.readonly,
          min: _vm.minYear,
          max: _vm.maxYear,
        },
        on: {
          change: function ($event) {
            return _vm.cambio($event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "first",
            fn: function () {
              return [
                _c(
                  "b-form-select-option",
                  { attrs: { value: null, disabled: "" } },
                  [
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("global.form.select-year-placeholder")
                          ),
                        },
                      },
                      [_vm._v("Seleccione un año")]
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.yearValue,
          callback: function ($$v) {
            _vm.yearValue = $$v
          },
          expression: "yearValue",
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-invalid-feedback",
        [
          _c("validations-commons", {
            attrs: {
              requiredValue: _vm.validationsCommons.requiredValue,
              requiredMessage: _vm.validationsCommons.requiredMessage,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.description
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }