import Vue from 'vue';

export default class AlertService {
  public showError(instance: Vue, message: string, params?: any) {
    const alertMessage = instance.$t(message, params);
    (instance.$root as any).$bvToast.toast(alertMessage.toString(), {
      toaster: 'b-toaster-top-center',
      title: 'Error',
      variant: 'danger',
      solid: true,
      autoHideDelay: 5000,
    });
  }
  public showInfo(instance: Vue, message: string, params?: any) {
    const alertMessage = instance.$t(message, params);
    (instance.$root as any).$bvModal.msgBoxOk(alertMessage, {
      title: instance.$t('alert-service.info.title'),
      size: 'md',
      buttonSize: 'md',
      okVariant: 'primary',
      okTitle: instance.$t('alert-service.info.oktitle'),
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true,
    });
  }

  public showHttpError(instance: Vue, httpErrorResponse: any) {
    switch (httpErrorResponse.status) {
      case 0:
        this.showError(instance, 'error.server.not.reachable');
        break;

      case 400: {
        const arr = Object.keys(httpErrorResponse.headers);
        let errorHeader: string | null = null;
        let entityKey: string | null = null;
        for (const entry of arr) {
          if (entry.toLowerCase().endsWith('app-error')) {
            errorHeader = httpErrorResponse.headers[entry];
          } else if (entry.toLowerCase().endsWith('app-params')) {
            entityKey = httpErrorResponse.headers[entry];
          }
        }
        if (errorHeader) {
          const alertData = entityKey ? { entityName: instance.$t(`global.menu.entities.${entityKey}`) } : undefined;
          this.showError(instance, errorHeader, alertData);
        } else if (httpErrorResponse.data !== '' && httpErrorResponse.data.fieldErrors) {
          this.showError(instance, httpErrorResponse.data.message);
        } else {
          this.showError(instance, httpErrorResponse.data.message);
        }
        break;
      }

      case 404:
        this.showError(instance, 'error.http.404');
        break;

      default:
        this.showError(instance, httpErrorResponse.data.message);
    }
  }

  public showConfirm(instance: Vue, message: string, params?: any): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const alertMessage = instance.$t(message, params);
      (instance.$root as any).$bvModal
        .msgBoxConfirm(alertMessage, {
          title: instance.$t('alert-service.confirm.title').toString(),
          size: 'md',
          buttonSize: 'md',
          okVariant: 'primary',
          okTitle: instance.$t('alert-service.confirm.okTitle').toString(),
          cancelTitle: instance.$t('alert-service.confirm.cancelTitle').toString(),
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        })
        .then((value: boolean) => {
          resolve(value);
        })
        .catch(() => {
          reject();
        });
    });
  }
}
