import Component, { mixins } from 'vue-class-component';
import { Emit, Prop, PropSync, Ref, VModel } from 'vue-property-decorator';
import JhiDataUtils from '@/shared/data/data-utils.service';
import { File } from '../commons/file.model';
import type AlertaComponent from '@/components/alerta/alerta.component';

@Component({
  inheritAttrs: false,
})
export default class InputTxtComponent extends mixins(JhiDataUtils) {
  filelist: Array<any> = [];
  url: string = null;
  tipoFormatoInvalido = false;
  tamanioInvalido = false;
  nombreInvalido = false;
  file: {
    contentType: string;
    content: string;
    nombre: string;
    size: number;
    uri: string;
  } = {
    contentType: null,
    content: null,
    nombre: null,
    size: null,
    uri: null,
  };

  content: string = null;

  regexp: RegExp = null;

  @Prop({ required: true })
  id: string;

  @Prop({ default: '' })
  label: string;

  @Prop({ default: '' })
  tiposMime: string;

  @Prop({ default: true })
  valid: boolean;

  @VModel({ required: true })
  documento: File;

  @Prop({ default: '' })
  description: string;

  @Prop({ required: true })
  disabled: boolean;

  @PropSync('estadoInvalido', { type: Boolean })
  estado: boolean;

  /**
   * Tamaño máximo en KB
   */
  @Prop({ required: true, type: Number })
  tamanioMaximo: number;

  @Ref() readonly inputFile!: HTMLInputElement;

  @Ref()
  alerta!: AlertaComponent;

  mounted() {
    if (this.documento) {
      this.url = `${this.documento.uri}`;
      this.filelist.push({ name: this.documento.nombre });
    }
  }

  @Emit()
  onChange(): Promise<any> {
    if (this.validarTipoFormato() || this.validarTamanio() || this.validarNombre()) {
      this.estado = true;
    }

    if (this.tipoFormatoInvalido) {
      this.alerta.mostrar(this.$t('archivo-txt.valid.type'), 'danger', 20);
      return;
    }

    if (this.tamanioInvalido) {
      this.alerta.mostrar(this.$t('archivo-txt.valid.size', { size: this.tamanioMaximo / 1024 }), 'danger', 20);
      return;
    }

    if (this.nombreInvalido) {
      this.alerta.mostrar(this.$t('archivo-txt.valid.name'), 'danger', 20);
      return;
    }

    return new Promise(resolve => {
      const file = this.inputFile.files[0];
      this.filelist.push(file);
      if (this.filelist.length > 1) {
        this.filelist.splice(0, 1);
      }
      this.toBase64(file, base64Data => {
        this.content = base64Data;
        this.file.contentType = this.filelist[0].type;
        this.file.content = this.content;
        this.file.nombre = this.filelist[0].name;
        this.file.size = this.filelist[0].size;
        this.file.uri = this.documento ? this.documento.uri : null;
        this.documento = this.file;
        this.url = URL.createObjectURL(this.filelist[0]);
      });
      this.inputFile.value = '';
      this.alerta.mostrar(this.$t('archivo-txt.alert.success'), 'primary', 20);
      resolve(true);
    });
  }

  remove(mostrarAlerta) {
    this.filelist.splice(0, 1);
    this.resetInput();
    this.file.contentType = null;
    this.file.content = null;
    this.file.nombre = null;
    this.file.size = null;
    this.file.uri = null;
    if (mostrarAlerta) {
      this.alerta.mostrar(this.$t('archivo-txt.alert.delete'), 'primary', 20);
    }
  }

  resetInput() {
    this.estado = false;
    this.tamanioInvalido = false;
    this.nombreInvalido = false;
  }

  dragover(event) {
    event.preventDefault();
  }

  dragleave(event) {
    // Clean up
    event.currentTarget.classList.add('bg-gray-100');
    event.currentTarget.classList.remove('bg-green-300');
  }

  drop(event): any {
    if (this.filelist.length >= 1) {
      return;
    } else {
      event.preventDefault();
      this.inputFile.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    }
  }

  @Emit()
  private validarTipoFormato(): boolean {
    const extensiones = this.tiposMime.split(',');
    for (const index in extensiones) {
      const extension = extensiones[index];
      if (this.inputFile.files[0].name.toUpperCase().endsWith(extension.toUpperCase().trim())) {
        this.tipoFormatoInvalido = false;
        return false;
      }
    }
    this.tipoFormatoInvalido = true;
    return true;
  }

  /**
   * Valida el tamaño del documento.
   */
  @Emit()
  private validarTamanio(): boolean {
    if (this.inputFile.files[0].size / 1024 <= this.tamanioMaximo) {
      this.tamanioInvalido = false;
      return false;
    }
    this.tamanioInvalido = true;
    return true;
  }

  /**
   * Valida el nombre del documento.
   */
  @Emit()
  private validarNombre(): boolean {
    // eslint-disable-next-line no-misleading-character-class
    this.regexp =
      /^([a-z0-9]|á|á|é|é|í|í|ó|ó|ú|ú|Á|Á|É|É|Í|Í|Ó|Ó|Ú|Ú|ñ|ñ|Ñ|Ñ|ä|ä|ë|ë|ï|ï|ö|ö|ü|ü|Ä|Ä|Ë|Ë|Ï|Ï|Ö|Ö|Ü|Ü|à|à|è|è|ì|ì|ò|ò|ù|ù|À|À|È|È|Ì|Ì|Ò|Ò|Ù|Ù|!|@|#|\$|%|\^|&|\*|\)|\(|\+|=|\[|\]|\}|\||\{|~|<|>|\.|_|-| )+\.(txt)$/im;
    if (this.regexp.test(this.inputFile.files[0].name) && this.inputFile.files[0].name.length < 51) {
      this.nombreInvalido = false;
      return false;
    }
    this.nombreInvalido = true;
    return true;
  }

  public cambiarArchivo() {
    document.getElementById('file_' + this.id).click();
  }
}
