/* tslint:disable */
// prettier-ignore
import { Authority } from '@/shared/security/authority';

const Showcase = () => import('@/core/showcase/showcase.vue');
const Nominas = () => import('@/modules/nomina/nominas.vue');
const DispersionComponent = () => import('@/modules/dispersion/dispersion.vue');
const ConfigPrenominaComponent = () => import('@/modules/configuracion/configuracion.vue');
const PrenominaComponent = () => import('@/modules/prenomina/prenomina.vue');
const CierreComponent = () => import('@/modules/cierre/cierre.vue');
const ConciliacionComponent = () => import('@/modules/conciliacion/conciliacion.vue');
const MovimientosProgramadosComponent = () => import('@/modules/movimientos-programados/movimientos-programados.vue');

export default [
  {
    path: '/showcase',
    name: 'Showcase',
    component: Showcase,
  },
  {
    path: '/nominas',
    name: 'Nominas',
    component: Nominas,
    meta: { authorities: [Authority.OPERADOR] },
  },
  {
    path: '/dispersion',
    name: 'Dispersion',
    component: DispersionComponent,
    meta: { authorities: [Authority.OPERADOR] },
  },
  {
    path: '/configuracion',
    name: 'Configuracion',
    component: ConfigPrenominaComponent,
    meta: { authorities: [Authority.OPERADOR] },
  },
  {
    path: '/prenomina',
    name: 'Prenomina',
    component: PrenominaComponent,
    meta: { authorities: [Authority.OPERADOR] },
  },
  {
    path: '/cierre',
    name: 'Cierre',
    component: CierreComponent,
    meta: { authorities: [Authority.OPERADOR] },
  },
  {
    path: '/conciliacion',
    name: 'Conciliacion',
    component: ConciliacionComponent,
    meta: { authorities: [Authority.OPERADOR] },
  },
  {
    path: '/movimientos-programados',
    name: 'MovimientosProgramados',
    component: MovimientosProgramadosComponent,
    meta: { authorities: [Authority.OPERADOR] },
  },
];
