import { Module } from 'vuex';

export interface NominaStateStorable {
  data: {
    id: string;
    filtroMes: number;
    filtroAnio: string;
    filtroClave: string;
    filtroCurrPage: number;
    filtroPageSize: number;
  };
}

export const defaultNominaState: NominaStateStorable = {
  data: null,
};

export const nominaStore: Module<NominaStateStorable, any> = {
  state: { ...defaultNominaState },
  getters: {
    idNomina: state => state.data.id,
    filtroMes: state => state.data.filtroMes,
    filtroAnio: state => state.data.filtroAnio,
    filtroClave: state => state.data.filtroClave,
    filtroCurrPage: state => state.data.filtroCurrPage,
    filtroPageSize: state => state.data.filtroPageSize,
  },
  mutations: {
    idNomina(state, id) {
      if (!state.data) {
        state.data = {
          id: null,
          filtroMes: null,
          filtroAnio: null,
          filtroClave: null,
          filtroCurrPage: null,
          filtroPageSize: null,
        };
      }
      state.data.id = id;
    },
    filtrosNomina(state, filtros) {
      if (!state.data) {
        state.data = {
          id: null,
          filtroMes: null,
          filtroAnio: null,
          filtroClave: null,
          filtroCurrPage: null,
          filtroPageSize: null,
        };
      }
      state.data.filtroMes = filtros.mes;
      state.data.filtroAnio = filtros.anio;
      state.data.filtroClave = filtros.clave;
      state.data.filtroCurrPage = filtros.currPage;
      state.data.filtroPageSize = filtros.pageSize;
    },
  },
};
