var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: { path: "/movimientos-programados" } } },
        [
          _c("span", { staticClass: "icon-calendario font-weight-bold" }),
          _vm._v(" "),
          _c("span", {
            domProps: {
              textContent: _vm._s(_vm.$t("movimientos.title.programados")),
            },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }