import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

import PropertyCommons from '../commons/property.model';

import { mixins } from 'vue-class-component';

@Component
export default class InputSelectManyComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: string;

  @Prop({ required: true })
  public markedSelections!: Array<any>;

  @Prop({ required: true, type: Array })
  public options!: Array<any>;

  get selected(): Array<any> {
    return this.markedSelections;
  }

  set selected(newValue: Array<any>) {
    this.$emit('input', newValue);
  }
}
