import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TimelineComponent extends Vue {
  @Prop()
  item: string;

  clase: { [key in 'Configuracion' | 'Prenomina' | 'Dispersion' | 'Conciliacion' | 'Cierre']: string } = {
    Configuracion: '',
    Prenomina: '',
    Dispersion: '',
    Conciliacion: '',
    Cierre: '',
  };

  public created(): void {
    this.construirTimeLine();
  }

  construirTimeLine() {
    let itemEncontrado = false;
    Object.keys(this.clase).forEach(key => {
      if (key === this.item) {
        itemEncontrado = true;
        this.clase[key] = 'timeline-step active';
      } else if (itemEncontrado) {
        this.clase[key] = 'timeline-step disabled';
      } else {
        this.clase[key] = 'timeline-step';
      }
    });
  }
}
