import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import PropertyCommons from '../commons/property.model';

@Component
export default class InputDateComponent extends mixins(PropertyCommons) {
  @Prop({ required: true })
  public value!: string;

  @Prop({ default: '' })
  minDate: string;

  @Prop({ default: '' })
  maxDate: string;

  @Prop({ default: null })
  dateFormatOptions: string;

  get dateValue(): string {
    return this.value;
  }

  set dateValue(newValue: string) {
    this.$emit('input', newValue);
  }
  cambio(e) {
    this.$emit('change', e);
  }
}
