var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.title
        ? _c("h3", { staticClass: "m-h3" }, [_vm._v(_vm._s(_vm.title))])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function (item, i) {
        return _c(
          "span",
          { key: item.id },
          [
            _c(
              "b-card",
              {
                staticClass: "mb-4",
                attrs: { id: item.id },
                on: {
                  mouseover: function ($event) {
                    return _vm.removeClassActice(item.id)
                  },
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        staticClass: "center-felx card-contacto",
                        attrs: { col: "", lg: "1" },
                      },
                      [_c("span", { class: item.icon })]
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "felx-2" },
                      [
                        _c(
                          "b-col",
                          {
                            staticClass: "left-felx",
                            attrs: { col: "", lg: "8" },
                          },
                          [
                            _c("b-card-text", [
                              item.title
                                ? _c(
                                    "div",
                                    { staticClass: "font-weight-bolder" },
                                    [_vm._v(_vm._s(item.title))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.description
                                ? _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(item.description),
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              item.footer
                                ? _c("div", { staticClass: "text-muted" }, [
                                    _vm._v(_vm._s(item.footer)),
                                  ])
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          {
                            staticClass: "right-felx",
                            attrs: { col: "", lg: "3" },
                          },
                          _vm._l(item.badge, function (b) {
                            return _c(
                              "span",
                              { key: b.id, staticClass: "rt-1" },
                              [
                                b.badge
                                  ? _c(
                                      "b-badge",
                                      {
                                        attrs: {
                                          pill: "",
                                          variant: b.primary ? "primary" : "",
                                        },
                                      },
                                      [_vm._v(_vm._s(b.badge) + " ")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                item.badgeEjes
                  ? _c("b-row", { staticClass: "border-top pt-3" }, [
                      _c(
                        "div",
                        { staticClass: "large-labels" },
                        _vm._l(item.badgeEjes, function (b) {
                          return _c("div", { key: b.id, staticClass: "ll-1" }, [
                            _c("span", [
                              _vm._v(" " + _vm._s(b.badgeEjes) + " "),
                            ]),
                          ])
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "border-top pt-3" },
                  [
                    _c(
                      "b-col",
                      { attrs: { col: "", cols: "12" } },
                      [
                        _c("c-action-buttons", {
                          staticClass: "float-right",
                          attrs: {
                            params: { paramId: item[_vm.trackedBy] },
                            editView: _vm.editView,
                            detailsView: _vm.detailsView,
                          },
                          on: {
                            delete: function ($event) {
                              return _vm.prepareRemove(i)
                            },
                            edit: function ($event) {
                              return _vm.edit(i)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("c-delete-modal", {
        attrs: { id: _vm.id + "-removeEntityModal" },
        on: {
          onDelete: function ($event) {
            return _vm.remove()
          },
        },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }