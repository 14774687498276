import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';

import '@/shared/config/dayjs';
import DataUserComponent from './core/user/data-user.vue';
import AlertService from './shared/alert/alert.service';
import { Inject } from 'vue-property-decorator';

@Component({
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,
    'data-user': DataUserComponent,
    'jhi-footer': JhiFooter,
  },
})
export default class App extends Vue {
  @Inject('alertService') private alertService: () => AlertService;

  beforeMount() {
    window.addEventListener('keydown', this.preventF5);
  }

  preventF5(e: KeyboardEvent) {
    if (e.key == 'F5') {
      e.preventDefault();
      this.alertService().showInfo(this, this.$t('global.messages.info.detailf5').toString());
    }
  }

  destroyed() {
    window.removeEventListener('keydown', this.preventF5);
  }
}
