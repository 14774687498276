var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group multiselect-tags" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.label)),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _vm.tooltip
          ? _c(
              "span",
              [
                _c("b-icon", {
                  attrs: { id: _vm.id + "tooltip", icon: "question-circle" },
                }),
                _vm._v(" "),
                _c("b-tooltip", {
                  attrs: {
                    target: _vm.id + "tooltip",
                    title: _vm.tooltip,
                    placement: "right",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.description
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("v-multiselect-listbox", {
        class: _vm.valid == null ? "" : "is-invalid",
        attrs: {
          id: "id-tags",
          options: _vm.tags,
          "reduce-display-property": (option) => option.text,
          "reduce-value-property": (option) =>
            !_vm.typeValue ? option.value.id : option.value,
          "search-input-class": "custom-input-class",
          "show-select-all-buttons": false,
          "highlight-diff": "",
        },
        model: {
          value: _vm.selectedId,
          callback: function ($$v) {
            _vm.selectedId = $$v
          },
          expression: "selectedId",
        },
      }),
      _vm._v(" "),
      _vm.required && _vm.valid == false
        ? _c("div", { class: _vm.valid == null ? "" : "invalid-feedback" }, [
            _vm._v("\n    " + _vm._s(_vm.requiredMessage) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }