var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-col",
    { staticClass: "alert-info-card" },
    [
      _c("div", { staticClass: "border-top mb-4" }),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "aic-1", attrs: { "align-v": "center" } },
        [
          _c("b-col"),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "2" } }, [
            _c(
              "span",
              {
                staticClass:
                  "rounded-circle shadow p-3 mt-1 bg-white d-inline-block",
              },
              [_c("span", { staticClass: "icon-info" })]
            ),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { staticClass: "m-3 p-3 text-center", attrs: { cols: "6" } },
            [
              _c(
                "span",
                {
                  staticClass: "float-right text-center",
                  attrs: { id: "m-3-1" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "text-secondary",
                      domProps: {
                        textContent: _vm._s(
                          _vm.$t("global.messages.alert.add-items")
                        ),
                      },
                    },
                    [
                      _vm._v(
                        "\n          Da click en agregar para comenzar a subir tu información\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm.error
                    ? [
                        _c(
                          "h3",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.messages.alert.info")
                              ),
                            },
                          },
                          [_vm._v("No hay información aún")]
                        ),
                      ]
                    : [
                        _c(
                          "h3",
                          {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.messages.alert.error")
                              ),
                            },
                          },
                          [_vm._v("error con la información")]
                        ),
                      ],
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("b-col"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }