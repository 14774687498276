var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("span", { staticClass: "float-right" }, [
    _vm.caracteresRestantes >= 0
      ? _c("small", { staticClass: "form-text text-muted" }, [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("contador-caracteres.restante", {
                  caracteresRestantes: _vm.caracteresRestantes,
                })
              ),
            },
          }),
        ])
      : _c("small", { staticClass: "form-text text-danger" }, [
          _c("span", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t("contador-caracteres.sobrepasado", {
                  caracteresRestantes: Math.abs(_vm.caracteresRestantes),
                })
              ),
            },
          }),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }