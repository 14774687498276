import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import type { InformacionNomina } from '@/shared/nomina-client';
import { NominaApi } from '@/shared/nomina-client';
import AlertService from '@/shared/alert/alert.service';

@Component
export default class DetalleNominaComponent extends Vue {
  @Inject('nominaApi')
  private nominaApi: () => NominaApi;

  @Inject('alertService')
  private alertService: () => AlertService;

  @Prop()
  idNomina: string;

  nomina: InformacionNomina = {
    mes: null,
    anio: null,
    descripcion: null,
    tipo_nomina: null,
    eventos_nomina: [],
    activo: null,
    id: null,
    clave: null,
    mesNombre: null,
    eventoActual: {
      estatus: null,
      cvu: null,
      fecha: null,
      activo: null,
    },
  };

  public beforeMount(): void {
    this.construirDetalle();
  }

  construirDetalle() {
    this.nominaApi()
      .obtenerNomina(this.idNomina)
      .then(res => {
        this.nomina = res.data;
      })
      .catch(err => {
        this.alertService().showError(this, this.$t('mensaje-error.principal').toString());
      });
  }
}
