import { render, staticRenderFns } from "./alert-card.vue?vue&type=template&id=433f0a17&scoped=true&"
import script from "./alert-card.component.ts?vue&type=script&lang=ts&"
export * from "./alert-card.component.ts?vue&type=script&lang=ts&"
import style0 from "./alert-card.vue?vue&type=style&index=0&id=433f0a17&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "433f0a17",
  null
  
)

export default component.exports