var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    { staticClass: "mb-3" },
    [
      _c(
        "b-row",
        { staticClass: "d-none d-md-block" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-nav",
                {
                  staticClass: "timeline-steps small-font",
                  attrs: { justified: "" },
                },
                [
                  _c(
                    "b-nav-item",
                    { class: _vm.clase.Configuracion },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-icon", {
                                staticClass: "icono",
                                attrs: { icon: "gear" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("timeline.configuracion.nombre")
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    { class: _vm.clase.Prenomina },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-icon", {
                                staticClass: "icono",
                                attrs: { icon: "card-list" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("timeline.prenomina.nombre")
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    { class: _vm.clase.Dispersion },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-icon", {
                                staticClass: "icono",
                                attrs: { icon: "list-check" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("timeline.dispersion.nombre")
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    { class: _vm.clase.Conciliacion },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-icon", {
                                staticClass: "icono",
                                attrs: { icon: "file-check" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("timeline.conciliacion.nombre")
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-nav-item",
                    { class: _vm.clase.Cierre },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-icon", {
                                staticClass: "icono",
                                attrs: { icon: "door-closed" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("timeline.cierre.nombre")
                                ),
                              },
                            }),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "d-md-none" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-center",
              attrs: { lg: "6" },
            },
            [
              _c(
                "b-nav",
                { attrs: { vertical: "" } },
                [
                  _c("b-nav-item", { class: _vm.clase.Configuracion }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        { staticClass: "posicion" },
                        [
                          _c("b-icon", {
                            staticClass: "icono mr-2",
                            attrs: { icon: "gear" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("timeline.configuracion.nombre")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-nav-item", { class: _vm.clase.Prenomina }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        { staticClass: "posicion" },
                        [
                          _c("b-icon", {
                            staticClass: "icono mr-2",
                            attrs: { icon: "card-list" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("timeline.prenomina.nombre")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-nav-item", { class: _vm.clase.Dispersion }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        { staticClass: "posicion" },
                        [
                          _c("b-icon", {
                            staticClass: "icono mr-2",
                            attrs: { icon: "list-check" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("timeline.dispersion.nombre")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-nav-item", { class: _vm.clase.Conciliacion }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        { staticClass: "posicion" },
                        [
                          _c("b-icon", {
                            staticClass: "icono mr-2",
                            attrs: { icon: "file-check" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("timeline.conciliacion.nombre")
                          ),
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("b-nav-item", { class: _vm.clase.Cierre }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c(
                        "span",
                        { staticClass: "posicion" },
                        [
                          _c("b-icon", {
                            staticClass: "icono mr-2",
                            attrs: { icon: "door-closed" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(_vm.$t("timeline.cierre.nombre")),
                        },
                      }),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }