var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-container",
    [
      _vm.nomina
        ? _c(
            "b-row",
            {
              staticClass: "text-center text-blue-conacyt",
              staticStyle: {
                "border-radius": "18px",
                border: "2px solid #002663",
                padding: "20px 0px 20px 0px",
                "white-space": "nowrap",
                "font-size": "15px",
              },
              attrs: { "align-v": "center", "align-h": "center" },
            },
            [
              _c(
                "b-col",
                { staticClass: "d-none d-md-block", attrs: { cols: "auto" } },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.clave.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "espacio-normal" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.clave)),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "icon-info button espacio-normal",
                    attrs: { id: "popover-detalle-nomina" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-none d-md-block", attrs: { cols: "auto" } },
                [
                  _c("span", {
                    staticClass: "espacio",
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.tipo.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "espacio-normal" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.tipo_nomina)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-none d-md-block", attrs: { cols: "auto" } },
                [
                  _c("span", {
                    staticClass: "espacio",
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.anio.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "espacio-normal" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.anio)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-none d-md-block", attrs: { cols: "auto" } },
                [
                  _c("span", {
                    staticClass: "espacio",
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.mes.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "espacio-normal" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.mesNombre)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-none d-md-block", attrs: { cols: "auto" } },
                [
                  _c("span", {
                    staticClass: "espacio",
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.estatus.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "espacio-normal" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.eventoActual.estatus)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-md-none", attrs: { md: "4", lg: "4" } },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.clave.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "espacio-normal" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.clave)),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "icon-info button espacio-normal",
                    attrs: { id: "popover-detalle-nomina-md" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-md-none", attrs: { md: "4", lg: "4" } },
                [
                  _c("span", {
                    staticClass: "espacio",
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.tipo.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "espacio-normal" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.tipo_nomina)),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-md-none", attrs: { md: "4", lg: "4" } },
                [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.mes.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "mr-4" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.mesNombre)),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.anio.nombre")),
                    },
                  }),
                  _c("b", [_vm._v(" " + _vm._s(_vm.nomina.anio))]),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "d-md-none", attrs: { md: "4", lg: "4" } },
                [
                  _c("span", {
                    staticClass: "espacio",
                    domProps: {
                      textContent: _vm._s(_vm.$t("detalle.estatus.nombre")),
                    },
                  }),
                  _c("b", { staticClass: "espacio-normal" }, [
                    _vm._v(" " + _vm._s(_vm.nomina.eventoActual.estatus)),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "popover-detalle-nomina",
            triggers: "hover",
            placement: "top",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "text-default" },
            [
              _c("b-col", [
                _c("h6", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("detalle.etiquetas.detalle-nomina")
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "text-blue-conacyt" },
            [
              _c("b-col", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("detalle.descripcion.nombre")),
                  },
                }),
                _c("b", { staticClass: "espacio-normal" }, [
                  _vm._v(" " + _vm._s(_vm.nomina.descripcion)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "popover-detalle-nomina-md",
            triggers: "hover",
            placement: "top",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "text-default" },
            [
              _c("b-col", [
                _c("h6", {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("detalle.etiquetas.detalle-nomina")
                    ),
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "text-blue-conacyt" },
            [
              _c("b-col", [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(_vm.$t("detalle.descripcion.nombre")),
                  },
                }),
                _c("b", { staticClass: "espacio-normal" }, [
                  _vm._v(" " + _vm._s(_vm.nomina.descripcion)),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }