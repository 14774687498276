var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.label) + " "),
        _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
        _vm._v(" "),
        _vm.tooltip
          ? _c(
              "span",
              [
                _c("b-icon", {
                  attrs: { id: _vm.id + "tooltip", icon: "question-circle" },
                }),
                _vm._v(" "),
                _c("b-tooltip", {
                  attrs: {
                    target: _vm.id + "tooltip",
                    title: _vm.tooltip,
                    placement: "right",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("b-form-checkbox-group", {
        attrs: {
          id: _vm.id,
          options: _vm.options,
          state: _vm.valid,
          stacked: "",
          disabled: _vm.readonly,
        },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _vm._v(" "),
      _c("b-form-invalid-feedback", { attrs: { state: _vm.valid } }, [
        _vm._v(" " + _vm._s(_vm.validationsCommons.requiredMessage) + " "),
      ]),
      _vm._v(" "),
      _vm.description
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }