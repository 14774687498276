var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.label
        ? _c("label", { attrs: { for: _vm.id } }, [
            _vm._v("\n    " + _vm._s(_vm.label) + " "),
            _vm.required ? _c("span", [_vm._v("*")]) : _vm._e(),
            _vm._v(" "),
            _vm.tooltip
              ? _c(
                  "span",
                  [
                    _c("b-icon", {
                      attrs: {
                        id: _vm.id + "tooltip",
                        icon: "question-circle",
                      },
                    }),
                    _vm._v(" "),
                    _c("b-tooltip", {
                      attrs: {
                        target: _vm.id + "tooltip",
                        title: _vm.tooltip,
                        placement: "right",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("b-form-datepicker", {
        staticClass: "mb-2",
        attrs: {
          id: _vm.id,
          state: _vm.valid,
          placeholder: _vm.placeholder,
          "today-button": "",
          "close-button": "",
          locale: "sp",
          "label-today-button": _vm.$t("seleccionar-fecha"),
          "label-close-button": _vm.$t("btn.cerrar"),
          min: _vm.minDate,
          max: _vm.maxDate,
          readonly: _vm.readonly,
          disabled: _vm.disabled,
          "date-format-options": _vm.dateFormatOptions,
        },
        on: {
          input: function ($event) {
            return _vm.cambio($event)
          },
        },
        model: {
          value: _vm.dateValue,
          callback: function ($$v) {
            _vm.dateValue = $$v
          },
          expression: "dateValue",
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-invalid-feedback",
        [
          _c("validations-commons", {
            attrs: {
              requiredValue: _vm.validationsCommons.requiredValue,
              requiredMessage: _vm.validationsCommons.requiredMessage,
              minValue: _vm.validationsCommons.minValue,
              minMessage: _vm.validationsCommons.minMessage,
              maxValue: _vm.validationsCommons.maxValue,
              maxMessage: _vm.validationsCommons.maxMessage,
              validValue: _vm.validationsCommons.validValue,
              validMessage: _vm.validationsCommons.validMessage,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.description
        ? _c("small", { staticClass: "form-text text-muted" }, [
            _vm._v(_vm._s(_vm.description)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }