var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("ribbon"),
      _vm._v(" "),
      _c("jhi-navbar"),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper toggled", attrs: { id: "app" } }, [
        _c(
          "div",
          { attrs: { id: "page-content-wrapper" } },
          [
            _c(
              "header",
              { staticClass: "header-conacyt" },
              [_c("data-user")],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "container plr-50" }, [
              _c("div", [_c("router-view")], 1),
            ]),
            _vm._v(" "),
            _c("jhi-footer"),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }